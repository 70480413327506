@use "src/_app/styles/mixins.scss" as *;
.wallets_item {
  position: relative;
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 8px 16px;
  background: var(--select_default);
  cursor: pointer;

  @include transition;
  width: 100%;
  text-align: start;
  color: var(--primary);

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);

    & > div {
      background: var(--select_item_hover);
    }
  }

  &:focus-visible {
    outline: 1px solid var(--primary);
    outline-offset: -1px;
  }
}
