@use "src/_app/styles/mixins.scss" as *;
.nav_title {
  display: flex;
  column-gap: 2px;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;

  @include transition;
  user-select: none;
  outline-color: var(--white) !important;
  border-radius: 10px;

  p {
    margin: 0;
    white-space: nowrap;
    color: var(--white);
  }

  .nav_arrow {
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:active,
  &.open {
    .nav_arrow,
    p {
      @include transition;
      color: #b1b1b1;
    }
  }

  &.open {
    .nav_arrow {
      transform: rotate(180deg);
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  color: var(--secondary);

  &.sub {
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid var(--border_header);
  }
}
