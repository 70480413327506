@use "src/_app/styles/mixins.scss" as *;
.menu_title {
  background: #1a1a1a;
  border-radius: 38px;
  color: var(--white);
  padding: 1px 6px;
  margin-left: -8px;

  @include respond(laptopL) {
    display: none;
  }
}

.menu_btn {
  width: 24px;
  height: 24px;
  color: var(--white);
  background: transparent;
  border: none;
  outline: none;

  @include transition;
  position: relative;
  cursor: pointer;

  &:focus-visible {
    outline-color: var(--white) !important;
    border-radius: 10px;
  }

  &:hover,
  &:active,
  &.open {
    color: #b1b1b1;
  }

  @include respond(laptopL) {
    display: none;
  }

  &.long_lang {
    @include respond(laptopL) {
      display: none;
    }
  }
}

.links {
  display: flex;
  flex-flow: column nowrap;
  color: var(--primary);
}

.dropdownTrigger__wrapper {
  display: flex;
}
