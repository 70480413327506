@use "src/_app/styles/mixins.scss" as *;
.links__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 16px;
  padding: 8px 16px;

  @include transition;
  cursor: pointer;
  text-align: start;
  width: 100%;

  &:hover,
  &:active {
    background: var(--bgHeaderItemHover);
  }

  span {
    color: var(--primary);
  }

  .content {
    display: flex;
    flex-direction: column;
  }
}

.item__content__desc {
  color: var(--secondary_header) !important;
}
