@use "src/_app/styles/mixins.scss" as *;
.wrapper {
  width: 100%;

  @include respond(tablet) {
    max-height: 110vh;
    height: 100%;
    overflow: hidden auto;
    padding: 0 8px;
  }

  @include respond(tabletS) {
    padding: 0;
  }

  &.burger {
    padding: 0;
  }
}

.divider {
  margin: 7.5px 16px;
  height: 1px;
  max-width: 100%;
  background: var(--border_header);
  border-radius: 1px;

  @include transition;
}

.subItem {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border_header);
  padding-top: 8px;
  margin-top: 8px;

  &.burger {
    border: none;
    padding: 0;
    margin: 0;
  }
}
